
import { computed, defineComponent, onMounted, ref } from "vue";
import RoomService from "@/core/services/RoomService";
import { IRoom } from "@/core/data/room";
import { IRoomGroup } from "@/core/data/roomGroups";
import Swal from "sweetalert2";
import AddUserModal from "@/views/studio-lab/components/modals/AddUserModal.vue";
import ViewGroupMemberModal from "@/views/studio-lab/components/modals/ViewGroupMemberModal.vue";
import { useRoute, useRouter } from "vue-router";
import { IRoomUser } from "@/core/data/roomUser";
export default defineComponent({
  components: { AddUserModal, ViewGroupMemberModal },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const addRoomMemberModal = ref();
    const formRef = ref<null | HTMLFormElement>(null);
    const deleteRoomUserIdList = ref<any[]>([]);
    const loading = ref<boolean>(false);
    const roomData = ref<IRoom>({
      id: null,
      name: "",
      created_at: new Date(),
      room_users: [],
      room_groups: []
    });
    const excludedUserIds = ref<Array<number>>([]);
    const roomUsers = ref<Array<IRoomUser>>([]);

    const viewGroupMemberRef = ref();
    const roomGroup = ref<IRoomGroup>();

    const showGroupMembers = (group: IRoomGroup) => {
      roomGroup.value = group;
      viewGroupMemberRef.value.showModal();
    }

    const rules = computed(() => {
      return {
        name: [
          {
            required: true,
            message: "Please input room name.",
            trigger: "blur",
          },
          {
            max: 255,
            message: "Room name must be no longer than 255 characters.",
            trigger: "blur",
          },
        ],
      };
    });

    const initRoom = () => {
      RoomService.getRoomById(route.params.id).then((response) => {
        roomData.value = response.data;
        roomUsers.value = roomData.value.room_users;
        roomUsers.value.forEach(roomUser => {
          excludedUserIds.value.push(roomUser.user.id);
        });
      });
    };

    const onSubmit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;

          var formData = new FormData();

          roomUsers.value.forEach((roomUser) => {
            formData.append("roomUsers[]", JSON.stringify(roomUser));
          });
          formData.append("roomData", JSON.stringify(roomData.value));
          if (roomData.value.id == null) {
            RoomService.createRoom(formData)
              .then((response) => {
                loading.value = false;
                Swal.fire({
                  text: "Save complete",
                  icon: "success",
                  showConfirmButton: false,
                  timer: 1500,
                });
                router.push({
                  name: "User Room management"
                });
              })
              .catch(({ response }) => {
                loading.value = false;
                Swal.fire({
                  title: "Error",
                  text: response.data.message,
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: "OK",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                });
              });
          } else {
            deleteRoomUserIdList.value.forEach((roomUserId) => {
              formData.append("delete_room_user_id_list[]", roomUserId);
            });
            RoomService.updateRoom(formData)
              .then((response) => {
                loading.value = false;
                Swal.fire({
                  text: "Save complete",
                  icon: "success",
                  showConfirmButton: false,
                  timer: 1500,
                });
                router.push({
                  name: "User Room management"
                });
              })
              .catch(({ response }) => {
                loading.value = false;
                Swal.fire({
                  title: "Error",
                  text: response.data.message,
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: "OK",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                });
              });
          }
        } else {
          return false;
        }
      });
    };

    const openUserDialog = () => {
      addRoomMemberModal.value.showModal();
    };

    const addRoomMember = (user) => {
      roomUsers.value.push({
        id: null,
        user: user,
        room: null,
      });
      excludedUserIds.value.push(user.id);
    };

    const deleteMember = (roomUser) => {

      let deleteRoomUserIndex = roomUsers.value.findIndex(
        (obj) => obj.user!.id === roomUser.user.id
      );

      if (roomUsers.value[deleteRoomUserIndex].id != null) {
        deleteRoomUserIdList.value.push(
          roomUsers.value[deleteRoomUserIndex].id
        );
      }

      roomUsers.value.splice(deleteRoomUserIndex, 1);

      excludedUserIds.value.splice(
        excludedUserIds.value.indexOf(roomUser.user.id),
        1
      );
    };

    const goBack = () => {
      router.back();
    };

    onMounted(() => {
      if (route.params.id != undefined) {
        initRoom();
      }
    });

    return {
      formRef,
      loading,
      roomData,
      rules,
      onSubmit,
      openUserDialog,
      addRoomMember,
      addRoomMemberModal,
      deleteMember,
      excludedUserIds,
      initRoom,
      roomUsers,
      goBack,
      viewGroupMemberRef,
      roomGroup,
      showGroupMembers
    };
  },
});
